// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vigp-tarifario-frontend-primary: mat.define-palette(mat.$indigo-palette);
$vigp-tarifario-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$indigo-palette-200: mat.get-color-from-palette($vigp-tarifario-frontend-primary, 200); // Default primary
$indigo-palette-400: mat.get-color-from-palette($vigp-tarifario-frontend-primary, 400); // Default primary
$indigo-palette-800: mat.get-color-from-palette($vigp-tarifario-frontend-primary, 800); // Darker shade

// The warn palette is optional (defaults to red).
$vigp-tarifario-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$vigp-tarifario-frontend-theme: mat.define-light-theme((
  color: (
    primary: $vigp-tarifario-frontend-primary,
    accent: $vigp-tarifario-frontend-accent,
    warn: $vigp-tarifario-frontend-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vigp-tarifario-frontend-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


// FONT ICONS
.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

//Icons
mg-icon {
  display: flex;
  justify-content: center;
}

@import "assets/custom-styles/angular-material";


textarea:focus, input:focus{
  outline: none;
}

button:focus, button:focus-visible{
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] { -moz-appearance:textfield; }


//SCROLL
::-webkit-scrollbar {
  width: 12px;
  height: 14px;
}
::-webkit-scrollbar-button {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: $indigo-palette-800;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track {
  background: $indigo-palette-200;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: $indigo-palette-400;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.non-editable-field {
  height: 30px;
  padding: 4px 6px;
  border-radius: 0.4rem;
  background: transparent;
  transition: 150ms ;
  border: 2px solid transparent;
}

.non-editable-field::placeholder {
  color: #d0d8ff;
}

.non-editable-field.error-field{
  color: #f15044;
}

.non-editable-field.error-field::placeholder {
  color: #f15044;
}

.editable-field {
  height: 30px;
  padding: 4px 6px;
  border: 2px solid #d0d8ff;
  border-radius: 0.4rem;
  background: white;
  transition: 150ms ease;
}

.editable-field::placeholder {
  color: #d0d8ff;
}

.editable-field.error-field{
  height: 30px;
  padding: 6px;
  border: 2px solid #f15044;
  border-radius: 0.4rem;
  transition: 150ms ease;
}

.editable-field.error-field::placeholder {
  color: #f15044;
}
