//MAT INPUTS

mat-form-field{
  font-size: small !important;
  font-weight: 500;
}

//BORDES 
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0.4rem !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-left-radius: 0;
  border-top-right-radius: 0.4rem !important;
  border-bottom-right-radius: 0.4rem !important;
  border-bottom-left-radius: 0;
}

//INITIAL STATE
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgb(208, 216, 255) !important;
}

//FOUCS STATE
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing{
  border-color: rgb(178, 187, 233) !important;
}

//ERROR STATE
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch, .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgb(253, 114, 104) !important;
}

//DISABLED STATE

.mat-form-field-disabled .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  border-top: 2px solid !important;
  border-bottom: 2px solid !important;
}

.mat-form-field-disabled .mdc-notched-outline__leading {
  border-left: 2px solid !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading, .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch, .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(200, 207, 241, 0.658) !important;
}

.mat-form-field-disabled .mdc-notched-outline__trailing {
  border-right: 2px solid !important;
}

.mat-form-field-disabled .mdc-text-field--outlined .mdc-notched-outline {
  background: #dbdff661;
  border-radius: 0.35rem;
}

//READONLY STATE

.mat-mdc-form-field.readonly {
  pointer-events: none;
}

.mat-mdc-form-field.readonly .mdc-text-field--outlined .mdc-text-field__input {
  color: rgb(0 0 0 / 55%) !important;
}

.mat-mdc-form-field.readonly  .mdc-text-field--outlined .mdc-notched-outline__leading,
.mat-mdc-form-field.readonly  .mdc-text-field--outlined .mdc-notched-outline__notch,
.mat-mdc-form-field.readonly  .mdc-text-field--outlined .mdc-notched-outline__trailing {
  background: #dbdff661;
  border-color: rgba(200, 207, 241, 0.658) !important;
}

.mat-mdc-form-field.readonly .mat-form-field .mdc-notched-outline__trailing {
  border-right: 2px solid !important;
}

.mat-mdc-form-field.readonly .mat-form-field .mdc-text-field--outlined .mdc-notched-outline {
  background: #dbdff661;
  border-radius: 0.35rem;
}


//HEIGHT PADDING
.mat-mdc-form-field-infix {
  min-height: 44px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  display: flex !important;
}

//PADDING RIGHT TO SUFFIX
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 0.75rem !important;
}

.mat-mdc-form-field-icon-suffix {
  margin-top: -2px;
}

//MAT ERROR

mat-error{
  font-size: 11px;
  line-height: 10px;
}


//MAT DATEPICKER
mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  height: auto;
  padding: 0.4rem 0px;
  width: auto;
}

mat-datepicker-toggle{
  .mat-mdc-button-persistent-ripple::before, .mat-ripple.mat-mdc-button-ripple {
    opacity: 0 !important;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 35px !important;
  }
}


// TABLAS 
table.mat-mdc-table{
  border-collapse: separate !important;
}

tbody .mat-mdc-cell {
  border-bottom: none !important;
}

.vigp-list tbody tr:nth-child(odd) {
  background-color: #f2f7f9; /* Light gray for odd rows */
}

.vigp-list tbody tr:nth-child(even) {
  background-color: #fff; /* White for even rows */
}

.mat-header-row {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

th.mat-mdc-header-cell{
  z-index: 11 !important;
}


// PAGINATION

div.mat-mdc-select-panel {
  box-shadow: 0px 4px 15px -3px rgb(0 0 0 / 21%) !important;
}


//DATE-PICKER

.mat-datepicker-content {
  display: block;
  box-shadow: 0px 4px 15px -3px rgb(0 0 0 / 21%) !important;
  border-radius: 0.5rem !important;

  .mat-mdc-option {
    min-height: 2.5rem !important;
  }
}

// AUTOCOMPLETE

div.mat-mdc-autocomplete-panel {
  border-radius: 0.4rem !important;
  box-shadow:  0px 4px 15px -3px rgb(0 0 0 / 21%) !important;
}

// DIALOG

.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: 0px 4px 15px -3px rgb(0 0 0 / 21%) !important;  
  border-radius: 0.5rem !important;
}

// MAT LIST - OPTIONS

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-left: 4px !important;
  margin-right: 4px !important;
}


.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: auto !important;
}

.mdc-list-item__primary-text {
  font-family: var(--mdc-list-list-item-label-text-font);
  line-height: var(--mdc-list-list-item-label-text-line-height);
  font-size: 14px !important;
  font-weight: var(--mdc-list-list-item-label-text-weight);
  letter-spacing: var(--mdc-list-list-item-label-text-tracking);
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}


.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #e56e00 !important;
  background-color: #ff7b00 !important;
}



//CHECKER

.mdc-checkbox .mdc-checkbox__native-control:focus:checked~.mdc-checkbox__ripple {
  opacity: 0 !important;
  background-color: transparent !important;
}

//BOTONES

.vigp-btn{
  font-size: 13px;
  border-radius: 0.4rem;
  padding: 0.25rem 0.75rem;
  height: 2.75rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.vigp-list-act-btn{
  padding: 4px;
  border-radius: 6px;
  height: 24px;
  width: 24px;
}


//TABS
.mat-mdc-tab-labels{
  border-bottom: 1px solid #ebebeb;
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 3px !important;
  border-color: #ff7b00 !important;
}

.mdc-tab__text-label{
  letter-spacing: normal !important;
  height: 100%;
}

.mat-mdc-tab-body.mat-mdc-tab-body-active {
  overflow-y: inherit !important;
}

.mat-mdc-tab-body-content {
  height: 100%;
  overflow: inherit !important;
}

.mat-mdc-tab.mdc-tab {
  padding: 0px;
}

// TOGGLE BUTTON

mat-pseudo-checkbox {
  display: none !important;
}

.mat-button-toggle-label-content{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.mat-button-toggle-label-content p.selected{
  color: white !important;
}

// TOOLTIP

.error-tootltip{
  --mdc-plain-tooltip-container-color: #f15044;
}

// AUTOCOMPLETE 
.mat-mdc-autocomplete-panel{
  padding: 0px !important;
}
